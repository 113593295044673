<template>
  <div id="app">
    <router-view></router-view>
    <page-footer/>
  </div>
</template>

<script>
import PageFooter from './components/page-footer'
export default {
  components: {
    PageFooter
  },
  data() {
    return {
    }
  },
  // computed: {
  //   keepAliveArray: {
  //     get() {
  //       return this.$store.state.common.keepAliveArray
  //     },
  //     set(arr) {
  //       this.$store.commit('common/updateKeepAliveArray',arr)
  //     }
  //   }
  // },
  // watch: {
  //   $route (route) {
  //     let array = this.keepAliveArray || []
  //     if (route.meta.keepAlive && !array.includes(route.name)) {
  //       array.push(route.name)
  //     }
  //     this.keepAliveArray = array
  //   }
  // }
}
</script>
<style lang="scss">
@import 'https://at.alicdn.com/t/c/font_3942476_9auxx6csesj.css';
@import "./assets/css/common.scss";
#app {
  font-family: 'Avenir', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #2c3e50;
}

body {
  overflow-x: hidden;
  overflow-y: auto;
}
.scroll{
  scrollbar-color:  #999 #dbdbdb;  /* 第一个方块颜色，第二个轨道颜色(用于更改火狐浏览器样式) */
  scrollbar-width: thin;  /* 火狐滚动条无法自定义宽度，只能通过此属性使滚动条宽度变细 */
  -ms-overflow-style:none;  /* 隐藏滚动条（在IE和Edge两个浏览器中很难更改样式，固采取隐藏方式） */

  /* 以下是chrome浏览器自定义滚动条样式方式 */
  &::-webkit-scrollbar {/*滚动条整体样式*/
    width: 6px;     /*高宽分别对应横竖滚动条的尺寸*/
    height: 12px;
  }
  &::-webkit-scrollbar-thumb {/*滚动条里面小方块*/
    border-radius: 12px;
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.1);
    background: #c2c2c2;
  }
  &::-webkit-scrollbar-track {/*滚动条里面轨道*/
    -webkit-box-shadow: inset 0 0 1px rgba(0,0,0,.1);
    border-radius: 12px;
    background: #ececec;
  }
}
.iconfont {
  font-family: "iconfont" !important;
  font-style: normal;
  -webkit-font-smoothing: antialiased;
}

/*element滚动条*/
.sys-zd .el-scrollbar__wrap {
  overflow-x: hidden !important;
  margin-bottom: 0 !important;
}
#nav {
  padding: 30px;

  a {
    font-weight: bold;
    color: #2c3e50;

    &.router-link-exact-active {
      color: #409EFF;
    }
  }
}

.hide {
  display: none !important;
}

/*element滚动条*/
.sys-zd .el-scrollbar__wrap {
  overflow-x: hidden !important;
}

.sys-zd .el-scrollbar__thumb {
  background-color: rgba(255, 255, 255, .3) !important;
}
.back-btn {
  position: absolute;
  left: -50px;
  top: 0;
  width: 50px;
  padding: 20px 8px;
  background: #fff;
  border-radius: 10px 0 0 10px;
  box-shadow: 0 2px 12px 0 rgb(0 0 0 / 10%);
  z-index: 9;
  .iconfont {
    font-size: 24px;
  }
  &:before {
    content: '';
    position: absolute;
    right: -5px;
    top: 0;
    width: 6px;
    height: 100%;
    background: #fff;
    z-index: 9;
  }
}
</style>
