export default {
  namespaced: true,
  state: {
    // 当前页面
    currentPage: localStorage.getItem("curPage"),
    menuMap: {},
    keepAliveArray: [],
    searchForm: {}
  },
  mutations: {
    updateCurrentPage (state, currentPage) {
      state.currentPage = currentPage
      localStorage.setItem('curPage',currentPage)
    },
    updateMenuMap (state, menuMap) {
      state.menuMap = menuMap
    },
    updateKeepAliveArray(state, arr) {
      state.keepAliveArray = arr || []
    },
    updateSearchForm (state, form) {
      state.searchForm = form
    }
  }
}
