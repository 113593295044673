<template>
  <div class="zxw-select uf uf-ac">
    <div class="select-name" :style="{width: nameWidth + 'px'}">{{ name }}：</div>
    <div class="uf uf-ac fwrap-w radio">
<!--      <div class="option all" @click="allClickRadio">全部</div>-->
      <div class="option" :class="radioSelected === item.key ? 'on' : ''" v-for="(item,index) in options" :key="index" @click="changeRadio(item)">{{ item.label }}</div>
    </div>
  </div>
</template>
<script>
export default {
  name: 'zxw-select',
  props: {
    name: {
      type: String,
      required: true
    },
    //选择器名字宽度
    nameWidth:{
      type: String,
      default: '60'
    },
    //医生信息
    options:{
      type: Array,
      default() {
        return []
      }
    },
    defaultSelect: {
      type: String,
      default: null
    }
  },
  data() {
    return {
      moreBtnShow: false,
      radioSelected:''
    }
  },
  mounted() {
    if (this.defaultSelect !== null) {
      this.radioSelected = this.defaultSelect
    }
  },
  methods: {
    //单选
    changeRadio(item) {
      this.radioSelected = item.key
      this.$emit('selectCallback', item.key)
    },
    //单选全部
    allClickRadio(){
      this.radioSelected = ''
    }
  },
  watch: {
  },
}
</script>
<style>
  .zxw-select {
    color: #666;
  }
  .zxw-select .option{
    padding: 4px 10px;
    margin: 3px 4px;
    border-radius: 4px;
    box-sizing: border-box;
    cursor: pointer;
    border: 1px solid transparent;
  }
  .zxw-select .option.all{

  }
  .zxw-select .select-name {
    padding: 4px 0;
    margin: 3px 0;
    opacity: .7;
  }
  .zxw-select .radio .option:hover {
    color: #409eff;
    background: #f4f4f4;
  }
  .zxw-select .radio .option.on {
    color: #ffffff;
    background: #409eff;
  }
  .zxw-select .checkbox .option:hover {
    color: #409eff;
    background: #f4f4f4;
  }
  .zxw-select .checkbox .option.on {
    color: #409eff;
    border: 1px dotted #409eff;
    position: relative;
    overflow: hidden;
  }
  .zxw-select .checkbox .option.on:hover:after {
    content: '×';
    position: absolute;
    line-height: 29px;
    font-size: 14px;
    text-align: center;
    right: 0;
    top: 0;
    width: 14px;
    height: 100%;
    background: rgba(219,251,244,.5);
  }
</style>
