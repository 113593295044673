import Vue from 'vue'
import App from '@/App.vue'
import router from '@/router'
import store from '@/store'
import VueCookie from 'vue-cookie'
import httpRequest from '@/utils/httpRequest'
import { api } from '@/api'
import { cons } from '@/api/constant'
import '@/icons'
import '@/element-ui'
import '@/element-ui/zx-ui'
import '@/assets/style/theme/index.css'
import 'element-ui/lib/theme-chalk/display.css'
import 'element-ui/lib/theme-chalk/index.css'
import './element-ui/element-variables.scss'
import '@/assets/fonts/font.css'
import { getDictList, getDictMap, deepClone, encryptECB, decryptECB  } from '@/utils'
import cloneDeep from 'lodash/cloneDeep'
import animated from 'animate.css'
import md5 from 'js-md5'
// 将自动注册所有组件为全局组件
import dataV from '@jiaminghi/data-view'
import * as echarts from 'echarts'
// import 'animate.css'
import scroll from 'vue-seamless-scroll'

Vue.use(dataV)
Vue.use(animated)
Vue.use(scroll)


// 挂载自定义指令
require('@/utils/directive');

Vue.use(VueCookie);
Vue.config.productionTip = false;

Vue.prototype.$http = httpRequest;
Vue.prototype.$api = api;
Vue.prototype.$cons = cons;
Vue.prototype.$getDictMap = getDictMap;   // 查字典
Vue.prototype.$getDictList = getDictList; // 查字典list
Vue.prototype.$deepClone = deepClone;
Vue.prototype.$md5 = md5
Vue.prototype.animated = animated
Vue.prototype.$encryptECB = encryptECB
Vue.prototype.$decryptECB = decryptECB
Vue.prototype.echarts = echarts

;(function () {
  window.SITE_CONFIG = {};
})();
// 保存整站vuex本地储存初始状态
window.SITE_CONFIG['storeState'] = cloneDeep(store.state)

if (process.env.VUE_APP_MOCK === "true") {
  require("@/mock");
}

new Vue({
  router,
  store,
  render: h => h(App)
}).$mount("#app");
