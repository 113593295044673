import Vue from 'vue'
import router from '@/router'
import store from '@/store'
import CryptoJS from 'crypto-js'

let keyStr = "4DBC8C1A3AB17E47"


/**
 * 获取uuid
 */
export function getUUID () {
  return 'xxxxxxxx-xxxx-4xxx-yxxx-xxxxxxxxxxxx'.replace(/[xy]/g, c => {
    return (c === 'x' ? (Math.random() * 16 | 0) : ('r&0x3' | '0x8')).toString(16)
  })
}

/**
 * 树形数据转换
 * @param {*} data
 * @param {*} id
 * @param {*} pid
 */
export function treeDataTranslate (data, id = 'id', pid = 'parentId') {
  var res = []
  var temp = {}
  for (var i = 0; i < data.length; i++) {
    temp[data[i][id]] = data[i]
  }
  for (var k = 0; k < data.length; k++) {
    if (temp[data[k][pid]] && data[k][id] !== data[k][pid]) {
      if (!temp[data[k][pid]]['children']) {
        temp[data[k][pid]]['children'] = []
      }
      if (!temp[data[k][pid]]['_level']) {
        temp[data[k][pid]]['_level'] = 1
      }
      data[k]['_level'] = temp[data[k][pid]]._level + 1
      temp[data[k][pid]]['children'].push(data[k])
    } else {
      res.push(data[k])
    }
  }
  return res
}

/**
 * 清除登录信息
 */
export function clearLoginInfo () {
  Vue.cookie.delete('token')
  // window.sessionStorage.removeItem('menuList')
  window.sessionStorage.clear()
  store.commit('resetStore')
}

/**
 * 检查是否为空
 * @param data
 * @returns {boolean}
 */
export function checkIsNull (data) {
  if (data === 0 || data === 1) {
    return false
  }
  return !data || data === '' || data === null || data === 'null' || data === 'undefined'
}

export function sortJson (jsonObj) {
  let arr=[]
  let newJson = {}
  for(var key in jsonObj){
    arr.push(key)
  }
  arr.sort()
  for(var i in arr){
    newJson[arr[i]] = jsonObj[arr[i]]
  }
  return newJson
}

/**
 * 查字典工具类
 * @param groupId
 */
export function getDictMap (groupId, callback) {
  let dict = {}
  if (groupId) {
    this.$http({
      url: this.$http.adornUrl(this.$api.DICT.DICT_MAP),
      method: 'get',
      params: this.$http.adornParams({
        ids: groupId
      })
    }).then(({data}) => {
      if (data && data.code === 0) {
        if (callback) {
          callback(data.dict)
        }
      }
    })
  }
  return dict
}

/**
 * 查询字典list
 * @param groupIds 字典组id 用逗号分隔
 */
export function getDictList (groupIds, callback) {
  let dict = {}
  if (groupIds) {
    this.$http({
      url: this.$http.adornUrl(this.$api.DICT.DICT_LIST),
      method: 'get',
      params: this.$http.adornParams({
        ids: groupIds
      })
    }).then(({data}) => {
      if (data && data.code === 0) {
        if (callback) {
          callback(data.dict)
        }
      }
    })
  }
  return dict
}

/**
 * base64加密
 * @param input
 * @returns {string}
 */
export function encode64 (input) {
  let _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let output = ''
  let chr1
  let chr2
  let chr3
  let enc1
  let enc2
  let enc3
  let enc4
  let i = 0
  let copy = utf8Encode(input)
  while (i < copy.length) {
    chr1 = copy.charCodeAt(i++)
    chr2 = copy.charCodeAt(i++)
    chr3 = copy.charCodeAt(i++)
    enc1 = chr1 >> 2
    enc2 = ((chr1 & 3) << 4) | (chr2 >> 4)
    enc3 = ((chr2 & 15) << 2) | (chr3 >> 6)
    enc4 = chr3 & 63
    if (isNaN(chr2)) {
      enc3 = enc4 = 64
    } else if (isNaN(chr3)) {
      enc4 = 64
    }
    output = output +
      _keyStr.charAt(enc1) + _keyStr.charAt(enc2) +
      _keyStr.charAt(enc3) + _keyStr.charAt(enc4)
  }
  return output
}

/**
 * base64解密
 * @param input
 * @returns {string}
 */
export function decode64 (input) {
  let _keyStr = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789+/='
  let output = ''
  let chr1
  let chr2
  let chr3
  let enc1
  let enc2
  let enc3
  let enc4
  let i = 0
  let copy = input.replace(/[^A-Za-z0-9+=]/g, '')
  while (i < copy.length) {
    enc1 = _keyStr.indexOf(copy.charAt(i++))
    enc2 = _keyStr.indexOf(copy.charAt(i++))
    enc3 = _keyStr.indexOf(copy.charAt(i++))
    enc4 = _keyStr.indexOf(copy.charAt(i++))
    chr1 = (enc1 << 2) | (enc2 >> 4)
    chr2 = ((enc2 & 15) << 4) | (enc3 >> 2)
    chr3 = ((enc3 & 3) << 6) | enc4
    output = output + String.fromCharCode(chr1)
    if (enc3 !== 64) {
      output = output + String.fromCharCode(chr2)
    }
    if (enc4 !== 64) {
      output = output + String.fromCharCode(chr3)
    }
  }
  output = utf8Decode(output)
  return output
}

export function utf8Encode (string) {
  let copy = string.replace(/\r\n/g, '\n')
  let utfText = ''
  for (let n = 0; n < copy.length; n++) {
    let c = copy.charCodeAt(n)
    if (c < 128) {
      utfText += String.fromCharCode(c)
    } else if ((c > 127) && (c < 2048)) {
      utfText += String.fromCharCode((c >> 6) | 192)
      utfText += String.fromCharCode((c & 63) | 128)
    } else {
      utfText += String.fromCharCode((c >> 12) | 224)
      utfText += String.fromCharCode(((c >> 6) & 63) | 128)
      utfText += String.fromCharCode((c & 63) | 128)
    }
  }
  return utfText
}

export function utf8Decode (utfText) {
  let string = ''
  let i = 0
  let c = 0
  let c2 = 0
  let c3 = 0
  while (i < utfText.length) {
    c = utfText.charCodeAt(i)
    if (c < 128) {
      string += String.fromCharCode(c)
      i++
    } else if ((c > 191) && (c < 224)) {
      c2 = utfText.charCodeAt(i + 1)
      string += String.fromCharCode(((c & 31) << 6) | (c2 & 63))
      i += 2
    } else {
      c2 = utfText.charCodeAt(i + 1)
      c3 = utfText.charCodeAt(i + 2)
      string += String.fromCharCode(((c & 15) << 12) | ((c2 & 63) << 6) | (c3 & 63))
      i += 3
    }
  }
  return string
}

/**
 * 获取地址链接的参数（有时如uri/?code=CODE这样的地址 this.$route.query无法找到参数）
 * @param name
 * @returns {string|null}
 */
export function getQuery (name) {
  return decodeURIComponent((new RegExp('[?|&]' + name + '=' + '([^&;]+?)(&|#|;|$)').exec(location.href) || [null, ''])[1].replace(/\+/g, '%20')) || null
}

/**
 * 深拷贝
 * @param val
 */
export function deepClone (val) {
  return JSON.parse(JSON.stringify(val))
}

/**
 * 文件base64转File对象
 * @param dataUrl base64字符串
 * @param filename 文件名
 * @returns {File}
 */
export function dataURLtoFile (dataUrl, filename) {
  // eslint-disable-next-line one-var
  var arr = dataUrl.split(','),
    mime = arr[0].match(/:(.*?);/)[1],
    bstr = atob(arr[1]),
    n = bstr.length,
    u8arr = new Uint8Array(n)
  while (n--) {
    u8arr[n] = bstr.charCodeAt(n)
  }
  return new File([u8arr], filename, { type: mime })
}

/**
 * 根据生日查询年龄
 * @param birth
 * @returns {number}
 */
export function getAge(birth) {
  if (birth === null || birth === "" || birth === undefined) {
    return 0;
  } else {
    let age;
    let today = new Date();
    let nowYear = today.getFullYear();
    let nowMonth = today.getMonth() + 1;
    let nowDate = today.getDate();
    let birthday = new Date(birth);
    let birthYear = birthday.getFullYear();
    let birthMonth = birthday.getMonth() + 1;
    let birthDate = birthday.getDate();
    if (nowMonth - birthMonth < 0) {
      age = nowYear - birthYear - 1;
    } else if (nowMonth === birthMonth) {
      if (nowDate - birthDate >= 0) {
        age = nowYear - birthYear;
      } else {
        age = nowYear - birthYear - 1;
      }
    } else {
      age = nowYear - birthYear;
    }
    return age
  }
}
/**
 * 判断操作系统类型
 */
export function userSystemAgent() {
  let u = navigator.userAgent
  let ua = ''
  let isAndroid = u.indexOf('Android') > -1 || u.indexOf('Adr') > -1 //android终端
  let isiOS = !!u.match(/\(i[^;]+;( U;)? CPU.+Mac OS X/) //ios终端
  if (isAndroid) {
    ua = 'isAndroid'
  }else if (isiOS) {
    ua = 'isIOS'
  }else {
    ua = 'isPC'
  }
  return ua
}

export function random (min, max) {
  return Math.floor(Math.random() * (max - min + 1)) + min
}

/**
 * AES-ECB加密方法
 * @param word
 * @returns {string}
 */
export function encryptECB (word) {
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var srcs = CryptoJS.enc.Utf8.parse(word)
  var encrypted = CryptoJS.AES.encrypt(srcs, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
  return encrypted.toString()
}

/**
 * AES-ECB解密方法
 * @param word
 * @returns {string}
 */
export function decryptECB (word) {
  var key = CryptoJS.enc.Utf8.parse(keyStr)
  var decrypt = CryptoJS.AES.decrypt(word, key, {mode: CryptoJS.mode.ECB, padding: CryptoJS.pad.Pkcs7})
  return CryptoJS.enc.Utf8.stringify(decrypt).toString()
}
