export default {
  namespaced: true,
  state: {
    loginStatus: window.sessionStorage.getItem('loginStatus'),
    id: '',
    name: '',
    userName: '',
    mobile: '',
    email: '',
    orgCode: '',
    orgName: '',
    roleKeyList: [],
    userDiscountEntityList: [],
    accountType: '',
    info: [],
    unRead: 0,
    defaultAvatar: {
      userMale: require('../../../public/img/male.jpg'),
      userFemale: require('../../../public/img/female.jpg')
    },
    userHead: require('../../../public/img/wuyanzu.jpg'),
    registerTime: null
  },
  mutations: {
    updateLoginStatus(state, loginStatus) {
      window.sessionStorage.setItem("loginStatus", loginStatus)
      state.loginStatus = loginStatus
    },
    updateId(state, id) {
      state.id = id
    },
    updateUserHead(state, userHead) {
      state.userHead = userHead
    },
    updateName(state, name) {
      state.name = name
    },
    updateUserName(state, userName) {
      state.userName = userName
    },
    updateMobile(state, mobile) {
      state.mobile = mobile
    },
    updateEmail(state, email) {
      state.email = email
    },
    updateOrgCode(state, orgCode) {
      state.orgCode = orgCode
    },
    updateOrgName(state, orgName) {
      state.orgName = orgName
    },
    updateRoleKeyList(state, roleKeyList) {
      state.roleKeyList = roleKeyList || []
    },
    updateAccountType(state, accountType) {
      state.accountType = accountType
    },
    updateInfo(state, info) {
      state.info = info
    },
    updateUnRead(state, unRead) {
      state.unRead = unRead
    },
    updateRegisterTime(state, registerTime) {
      state.registerTime = registerTime
    },
    updateUserDiscountEntityList(state, userDiscountEntityList) {
      state.userDiscountEntityList = userDiscountEntityList
    }
  }
}
